<template>
  <PageHeader image="header-7" withMobile :title="$lang('title')" :breadcrumbs="$lang('breadcr')" />
  <!-- section -->
  <section class="pt-8 pb-9 bg-blue4">
    <div class="container">
      <div class="row mb-5">
        <div class="col-lg-12">
          <h2 class="fw-semibold text-blue2 mb-4">{{ $lang("section.title") }}</h2>
          <h3 class="fw-semibold text-green1 mb-4">{{ $lang("section.subtitle") }}</h3>
          <ol class="ml-4 d-flex flex-column gap-4">
            <li v-html="$lang('section.list1')"></li>
            <li v-html="$lang('section.list2')"></li>
            <li v-html="$lang('section.list3')"></li>
            <li v-html="$lang('section.list4')"></li>
            <li v-html="$lang('section.list5')"></li>
            <li v-html="$lang('section.list6')"></li>
            <li v-html="$lang('section.list7')"></li>
            <li v-html="$lang('section.list8')"></li>
            <li v-html="$lang('section.list9')"></li>
            <li v-html="$lang('section.list10')"></li>
            <li v-html="$lang('section.list11')"></li>
            <li v-html="$lang('section.list12')"></li>
            <li v-html="$lang('section.list13')"></li>
            <li v-html="$lang('section.list14')"></li>
            <li v-html="$lang('section.list15')"></li>
            <li v-html="$lang('section.list16')"></li>
            <li v-html="$lang('section.list17')"></li>
          </ol>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <Accordion :data="$lang('items')" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Accordion from "@/components/Accordion.vue";

export default {
  components: {
    PageHeader,
    Accordion,
  },
};
</script>

<style></style>
